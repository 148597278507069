export const staticContents = [
  ["about", "about-us"],
  ["ourServices", "our-services"],
  ["missionAndIntent", "mission-and-intent"],
  ["contactUs", "contact-us", "Contact Us"],
  ["MerchantRegister", "Merchant-Register", "Merchant Register"],
  ["servicesForManufacturers", "for-Manufacturers"],
  ["servicesForRetailers", "for-Retailers"],
  ["servicesForSalesAgencies", "for-Sale-Representatives"],
  ["createAMarketplace", "create-a-marketplace"],
  ["privacyAndSecurity", "privacy-and-security"], //Empty, unneeded? Other one can be renamed if customer prefers. Original term usage was inconsistent.
  ["socialMediaGroups", "social-media-and-groups"],
  ["partnersAndNetwork", "partners-and-network"],
  //["privacyPolicy", "privacy-policy", "Privacy Policy"], //Done and presentable. -RP
  ["shippingInformation", "shipping-information", "Shipping Policy"],
  ["returnPolicy", "return-policy", "Return Policy"],
  ["termsOfUse", "terms-of-use", "Dealer Terms & Conditions"], //Heavy wip. -RP
  ["sellerRegistration", "seller-registration", "Seller Sign Up"],
  ["sellerThankYou", "seller-thank-you", "Thank You"],
  ["contactDL", "contact", "Contact"], // Done and presentable. Could be improved with OFB stlye form? -RP
  ["customerService", "customer-service-topics", "Custome Service Topics"] //Heavy wip.-RP
];
//Status comments on all these can be removed once we've got all the pages ported. For now it helps with organization. -RP
//They all have to be moved anyways. Should be in proper static pages as mentioned in the training group

// try to edit something
