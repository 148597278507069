import React from "react";
import { PROJECT_LINK, PREVIEW } from "../../project-config.js";

export const returnPolicy = `<div class="row staticContent"><h1>Return Policy</h1><p>If you have a problem with goods received contact the Seller and advise of the problem.  Each seller will  have a different return policy and procedure.</p>

<p>If you view the Store page for the Seller you can learn more about their specific policies.</p>

<p>As your transaction is between you and the seller and all payment goes to the seller the OpenForBusinessMarketplace is not responsible for handling returns.</p>

<p>If you have any questions about our policies please contact us in the contact us section of the OpenForBusinessMarketplace site.</p></div>`;

export const shippingInformation = `<div class="row staticContent"><h1>Shipping Information</h1><p>Each Seller on the OpenForBusinessMarketplace will choose to either offer</p>

<ol>

<li>Curbside pickup at their store or a farmers market or other location</li>

<li>They will ship to you using a carrier they choose.</li>

</ol>

<p>When you place an order you will receive an order confirmation email that confirms where to pickup your order or advises that the order will be shipped.</p>

<p>When the order has been shipped or is ready for pickup you will receive a second order shipping or pickup confirmation email.  Do not travel to the sellers store until you receive the email that advises the order is ready for pickup.</p>

<p>If the order is shipped a tracking number may be included in the shipping confirmation email that you can click on to track your order.</p>

<p>You can also contact the seller directly to confirm delivery or pickup status.</p>

<p>If you have any questions about our policies please contact us in the contact us section of the OpenForBusinessMarketplace site.</p></div>`;

export const termsOfUse = `<div class=" staticContent"><h1>DFS Dealer Terms & Conditions</h1><p>These terms and conditions (“Agreement”), along with DFS’s Privacy Policy apply to your participation in the DFS Dealer Program (the “Program”). IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU CANNOT BE A DFS DEALER. DFS may update or change, augment or enhance this Agreement or the DFS Dealer Program without notice to you. By becoming a DFS Dealer and accepting this Agreement you agree to be bound by these modifications. If you do not agree to the changes your sole and exclusive remedy is to terminate your relationship with us.</p>
<h5>1. Appointment as a Dealer</h5>
<p>DFS hereby appoints the entity listed on the Dealer Application Form (hereinafter “you” or “Dealer”) as a non-exclusive Authorized DFS Dealer. As a dealer, you have the right to sell DFS products to end users in the form and packaging supplied by DFS.</p>
<h5>2. Placing and Acceptance of Orders</h5>
<p>You agree to submit orders to DFS from time to time for DFS products and services. Orders can be placed by internet, phone, fax or mail. Orders for cheques should include a bank specification sheet or a cheque marked "void" (sorry, no deposit tickets). First time orders with new artwork can only be accepted by internet or mail, as faxing can distort logo artwork. Custom logos and other customer artwork may be redrawn to meet printing specifications, for which there will be an additional charge. Imprint information will be printed in our standard format, as shown in the catalogue, unless otherwise noted on the order. Please specify starting number when applicable. Please note, the owner of any trademark, service mark, logo, or copyrighted material has the exclusive right to use and reproduce such material. You therefore cannot reproduce any unlicensed trademarks, service marks, logos, or copyrighted material onto printed materials and merchandise via the service offered through this web site without authorization from the owner. We may contact you to verify that this authorization is in place. All orders are subject to acceptance by DFS, as well as these Terms.</p>
<h5>3. Prices and Payment</h5>
<p>Prices are subject to change without prior notice or obligation. Prices do not include shipping if applicable or sales taxes, all of which are payable by you. Orders are subject to credit approval. Prepayment will speed first-time orders through our credit department. For Dealers having received credit approval, terms are net 30 days from the date of invoice. In the event that open account terms cannot be established, terms are Payment with Order. DFS accepts Visa, MasterCard and American Express. Initial orders for sales materials do not require prepayment. If an account is past due, any new orders will be held until payment is received for the outstanding balance. DFS reserves the right to change a Dealer's open account status if payment history warrants.</p>
<h5>4. Delivery</h5>
<p>We'll ship products with standard imprints within three working days. Orders with custom artwork and special printing instructions will take longer. Stock products ship in 24 hours. Except for specialty orders, DFS will prepay the freight charges using standard ground shipping and ship to dealer or end-users as specified.</p>
<h5>5. Support from DFS</h5>
<p>DFS will provide you with free sales tools to assist you in the sales of DFS products and services. DFS Dealer Representatives are available Monday through Friday 8:00am - 6:00pm ET at 800.490.6327. Dealer Representatives will gladly answer all questions concerning the DFS program, products, ordering and invoicing. For Custom Printing orders and inquiries, call 800.490.6327 during the same business hours.</p>
<h5>6. Warranty</h5>
<p>For 90 days from the day we ship your order, you are covered by our 100% "Quality Guarantee" which is applicable to all Printed Products. We will replace, refund or credit any of our manufactured products found to contain printing errors caused by us, or to be defective in workmanship or materials. Any printed product created using customer supplied print ready files or created using the services of our composition design team are also guaranteed to match your approved proof in all aspects.</p>
<h5>7. Limitation of Liability</h5>
<p>IN NO EVENT WILL DFS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY NATURE OR DESCRIPTION IN CONNECTION WITH THIS AGREEMENT OF THE PROGRAM, OR DAMAGES RESULTING FROM ANY USE OR MISUSE OF ANY PRODUCT OR ANY ACT OR OMISSION BY DFS, EVEN IF DFS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. DFS CUMULATIVE LIABILITY UNDER THIS AGREEMENT WILL BE LIMITED TO THE TOTAL OF ALL FEES PAID FOR THE 12-MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO ANY SUCH LIABILITY.</p>
<h5>8. DFS Materials</h5>
<p>Nothing contained in this Agreement grant, by implication, estoppel, or otherwise, any license or right to use any DFS logos, trademarks, copyrightable materials or other proprietary materials ("DFS Materials"). You shall not, directly or indirectly, undertake or engage in the following practices: (a) use or otherwise incorporate the word "Deluxe" or "DFS" or any other DFS Materials or variations, misspellings or derivatives thereof in the domain name of any websites you own or control; (b) make any representations, either express or implied, or create an appearance or impression, that (i) a visitor to your web site is visiting DFS.ca or any of our affiliated company sites (our Sites), (ii) a visitor to our Site is visiting your web site or (iii) DFS endorses you or your website or your products and services (for example, you are agreeing not to "frame" any pages or portions of deluxeforms.ca); (c) purchase, bid, or register for placement of any DFS or our affiliated companies trademarks (including "Deluxe" and "DFS"), or for any variations or deliberate misspellings thereof, within any search engine or directories; or (d) use predatory advertising methods designed to generate traffic from websites that do not have approval for the online promotion of our products or services. Predatory advertising is defined as any method that creates or overlays links or banners on web sites, spawns browser windows, or any method invented to generate traffic from a website without that website owner's expressed, written permission.</p>
<h5>9. Confidentiality</h5>
<p>A party may from time to time disclose (the "Disclosing Party") confidential information ("Confidential Information") to the other party (the "Recipient") in connection with the business relationship set forth herein. Recipient agrees to access, use and disclose the Confidential Information of Disclosing Party only in connection with fulfilling its obligations under this Agreement and for no other purposes, except that DFS may access, use and disclose such information to protect against actual or potential fraud or unauthorized transactions, to comply with legal requirements or for other purposes permitted by law. Confidential Information shall not be construed to mean any information which is: (a) or becomes part of the public domain without violation of this Section 6; (b) known by Recipient prior to disclosure by Disclosing Party; (c) rightfully received by Recipient from a third party having the right to disclose such information; or (d) developed by Recipient independent of Disclosing Party's otherwise Confidential Information.</p>
<h5>10. Indemnity</h5>
<p>You agree to defend, indemnify and hold DFS and its affiliated companies harmless from and against any and all liabilities, claims, damages, penalties, causes of action, costs and expenses (including, without limitation, attorneys' fee and costs of litigation) incurred by or asserted against DFS or any of its affiliated companies that arise out of or are incidental to your conduct of business under this Agreement or the products or services provided hereunder.</p>
<h5>11. Term and Termination</h5>
<p>These Terms shall be effective upon the earlier of DFS notifying you of your acceptance into DFS Program or DFS acceptance of your initial order and shall expire upon this Agreement being terminated by you or DFS as provided herein (the "Term"). Either party may at its sole discretion at any time terminate the Agreement with or without cause upon written notice to the other party. No termination of this Agreement shall affect the respective obligations or rights of the parties accruing hereunder prior to the effective date of such termination and, in addition, the parties hereby agree all terms and conditions that should by their nature survive termination of this Agreement shall so survive. Because these Terms may be terminated by either party at any time, with or without cause, you acknowledge and agree that (i) there is no commitment that your relationship with DFS shall exist for any minimum period and (ii) all investments and commitments made by you or expenses incurred by you are done so by you at your own risk and with the understanding that you shall not look to DFS to recover or recoup any such investments, commitments or expenses or any amounts for expected future profits in the event either you or DFS terminates this Agreement.</p>
<h5>12. Miscellaneous</h5>
<p>This Agreement shall be construed with the laws of state of Minnesota, excluding its conflict of law provisions. Any action relating to this Agreement must be brought in the federal or state courts located in Ramsey County, Minnesota, and you irrevocably consent to the jurisdiction of such courts. You may not assign this Agreement, by operation of law or otherwise, without DFS’s prior written consent. Subject to that restriction, this Agreement will be binding on, inure to the benefit of, and enforceable against the parties and their respective successors and assigns. If any clause of these Terms is declared illegal or invalid, the balance of these Terms shall remain enforceable, the clauses herein being severable. No act or failure to act by DFS hereunder shall constitute a waiver by DFS of any term or condition herein. No course of dealing between the parties will be deemed effective to modify or discharge any rights or obligations of either party. This Agreement and any documents incorporated herein by reference constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes any prior or contemporaneous agreements or understandings, written or oral, concerning the subject matter hereof. This Agreement constitute the final and complete agreement between you and DFS regarding the subject matter herein and supersedes all prior and contemporaneous, understandings, agreements and communications, written or oral, with respect to the subject matter hereof.</p>

</div>`;

//TODO: Probably delete? Using "contactDL" below. Kept for easy reference to form if needed in future.
export const contactUs = `<div class="row">

<div class="row">
      <div style="text-align: center">
          <h2 class="formHead">CONTACT THE OPENFORBUSINESS MARKETPLACE</h2>
      </div>
      </div>
     
      <div class="col-lg-12">
          <div class="card-panel">
              <h4 class="h4-responsive">Send an Email:</h4>
              <base target="_parent" />
              
              
              
             
              
              <form name="contactForm" method="post" action="https://c95prod.avetti.ca/preview/acsitenew/contactus/"  >
                  <table style="padding: 10px 6px 12px 10px; font-size: 0.9375em; width: 100%; background-position: 50% 0%; background-repeat: repeat-x;">
                      <tbody>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 1.125em; font-weight: bold; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4">
                          </td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.125em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.9375em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="left">
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 0.5em; font-weight: normal; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>First Name: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_firstName" type="text" name="FirstName" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Last Name: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_lastName" type="text" name="LastName" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                  </tr>
                      <tr>
                       <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Location: <span style="color: #ff0000;">*</span>
                     </span></td>
                        <td style="font-size: 0.9375em; font-weight: normal;" width="10%">
                              <select id="country" name="country" class="contactInput" style="display:block !important;">
                                  <option selected>Select Location</option>
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada" >Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">French Southern Ter</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">Netherland Antilles</option>
                                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                                  <option value="Republic of Serbia">Republic of Serbia</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">United Arab Emirates</option>
                                  <option value="United States of America">United States of America</option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">Vatican City State</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </select> 
                            <td style="font-size: 0.9375em; font-weight: normal;" width="85%">
      
                      
                         </td> 
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%">
                          <span>Phone: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_Phone" type="text" name="phone" class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Email Address: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span><input id="insightly_Email" type="text" name="email"  class="contactInput" /></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span>Message: <span class="required" style="color: #ff0000;">*</span></span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span id="sugarslot"><textarea id="message" type="text" name="message" class="contacttextarea" required/> </textarea></span></td>
                          <td style="text-align: left; font-size: 0.9375em; font-weight: normal;" width="15%"><span> </span></td>
                          <td style="font-size: 0.9375em; font-weight: normal;" width="85%"><span> </span></td>
                      </tr>
                      <tr style="color: #0069e1; font-family: Arial,Verdana,Helvetica,sans-serif; font-size: 1.125em; font-weight: bold; margin-bottom: 0px; margin-top: 0px;" align="center">
                          <td colspan="4"> </td>
                      </tr>
                      <tr align="left">
                          <td colspan="10">
                          
                          
                             
                              <input class="btn btn-default cafe-button" style="background-color: #217730 !important; color:#fff !important;" id="contact-continue-secure" type="submit" name="Submit" value="Submit" />
                          </td>
                      </tr>
                      
                      </tbody>
                  </table>
                  
                 
                          
                         
                          
              </form>
             
      
              <script>

             
              function submit_form(){
                  if(typeof(validateCaptchaAndSubmit)!='undefined'){
                      validateCaptchaAndSubmit();
                  }else{
                      check_webtolead_fields();
                  }
              }
              function check_webtolead_fields(){
                  if(document.getElementById('bool_id') != null){
                      var reqs=document.getElementById('bool_id').value;
                      bools = reqs.substring(0,reqs.lastIndexOf(';'));
                      var bool_fields = new Array();
                      var bool_fields = bools.split(';');
                      nbr_fields = bool_fields.length;
                      for(var i=0;i<nbr_fields;i++){
                          if(document.getElementById(bool_fields[i]).value == 'on'){
                              document.getElementById(bool_fields[i]).value = 1;
                          }
                          else{
                              document.getElementById(bool_fields[i]).value = 0;
                          }
                      }
                  }
                  if(document.getElementById('req_id') != null){
                      var reqs=document.getElementById('req_id').value;
                      reqs = reqs.substring(0,reqs.lastIndexOf(';'));
                      var req_fields = new Array();
                      var req_fields = reqs.split(';');
                      nbr_fields = req_fields.length;
                      var req = true;
                      for(var i=0;i<nbr_fields;i++){
                          if(document.getElementById(req_fields[i]).value.length <=0 || document.getElementById(req_fields[i]).value==0){
                              req = false;
                              break;
                          }
                      }
                      if(req){
                          document.WebToLeadForm.submit();
                          return true;
                      }
                      else{
                          alert('Please provide all the required fields');
                          return false;
                      }
                      return false
                  }
                  else{
                      document.WebToLeadForm.submit();
                  }
              }
             
              
                  $("[name='contactForm']").submit(function() {
                      if ($.trim($("#insightly_firstName").val()) === "" || 
                          $.trim($("#insightly_lastName").val()) === "" ||
                          $.trim($("#country").val()) === "" ||
                          $.trim($("#insightly_Phone").val()) === "" ||
                          $.trim($("#insightly_Email").val()) === "" ||
                          $.trim($("#message").val()) === "") {
                          
                          alert('Please, fill all the fields before submitting');
                          return false;
                          
                      } 
                      else {
                          $('#contactThankYouBack').removeClass('hidden'); return false;
                      }
                  });
              
              </script>
      
      <div id="contactThankYouBack" class="hidden">
      <div id="contactThankYouContain">
        <div id="contactThankYouHead">
            <a href="javascript:void(0);" class="pull-right" onclick="$('#contactThankYouBack').addClass('hidden');">X</a>
            <div class="clearfix"></div>
        </div>
        <div id="contactThankYouBody">
            <p style="font-size: 1.3125em; text-align: center; line-height: 25px; margin-bottom: 15px; margin-top: 15px; font-weight: 500;">Thank you for your interest in <br/>The OpenForBusiness Marketplace.</p>
      
            <p style="font-size: 1em; text-align: center; line-height: 23px; margin-bottom: 25px;">We will respond to your query via the e-mail address you provided.</p>
        </div>
        <div id="contactThankYouFoot">
            <a href="javascript:void(0);" class="btn btn-default" onclick="window.location.reload();
            ">Close</a>
        </div>
      </div>
      </div>




</div>
</div>

<div>
        <div class="col-lg-12">           

            <div>
            
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2 primary-contact-card" style="position: relative;    padding: 23px 13px; font-weight:normal;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti.com Corporation</h4>
                            <p style="font-size: 1em !important;"><strong>Canada</strong></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p>92 Caplan Avenue, Suite 206</p>
                            <p>Barrie, Ontario, L4N 9J2, Canada</p>
                            <br/>
                            <br/><br/><br/><br/>
                            <p><strong>Phone:</strong> +1 705 797 2105</p>
                            <p><strong>Hours:</strong> 9:30am - 5:30pm EST/EDT</p>                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><strong>E-Mail:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;" href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a><br/>  <strong>Directions:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Avetti.com+Corporation,+92+Caplan+Ave+%23206,+Barrie,+ON+L4N+0Z7,+Canada/@44.3384041,-7">Click Here</a></p>
                        
                    </div>
                </div>
                
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti <br/>MENA</h4>
                            <p style="font-size: 1em !important;"><strong>UAE</strong></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p>Level 7, Bay Square, Building 11</p>
                            <p>Business Bay, Dubai,UAE</p>
                            
                            <br/>
                            <br/><br/><br/><br/>
                            <p><strong>Phone:</strong> +971 04 556 1567</p>
                            <p><strong>Hours:</strong> 9am - 5pm GST</p>
                            
                        </div>
                        
                        <p style="position:absolute;bottom:0;line-height: 24px;"><strong>E-Mail:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a><br/> <strong>Directions:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Bay+Square+Building+11/@25.1852519,55.2790726,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f68334f3afdc7:0x29a00b6670b297d6!8m2!3d25.1852519!4d55.2812613">Click Here</a></p>
                        
                    </div>
                </div>
                
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti Commerce Pvt Ltd.</h4>
                            <p style="font-size: 1em !important;"><strong>India</strong></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p style="line-height: 24px;">H-No 1-62/K/36, 3rd Floor, Plot No:36, Spaces & More Business Park, Kavuri Hills Phase-1, Hyderabad Telangana, India 500081</p>
                            
                            <br/>
                            <p><strong>Phone:</strong> +91 040-42308877</p>
                            <p><strong>Hours:</strong> 10:30 am - 7:30 pm IST</p>
                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><strong>E-Mail:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommerceindia@avetti.com">ecommerceindia@avetti.com</a><br/>  <strong>Directions:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/Spaces+%26+More+Business+Center+@+Jubilee+Hills/@17.4377095,78.3985151,17z/data=!3m1!4b1!4m5!3m4!1s0x3bcb914619652db9:0x6017767f481c36d2!8m2!3d17.4377095!4d78.4007038">Click Here</a></p>
                        
                    </div>
                </div>
                <div class="col-md-3" style="padding-left:5px; padding-right:5px;">
                    <div class="card-panel c-panel-2" style="position: relative; padding: 23px 13px;">
                        <div class="card-panel-headlines">
                            <h4 class="">Avetti Global Services</h4>
                            <p style="font-size: 1em !important;"><strong>Panama</strong></p>
                            <br/>
                        </div>
                        
                        <div class="card-panel-info">
                            <p style="line-height: 24px;">Boulevard Panama Pacifico International Business Park Edificio 3825, Oficina 101B Panama Pacifico, Panama</p>
                            <br/>
                            <p><strong>Phone:</strong> +507 834 7000</p>
                            <p><strong>Hours:</strong> 9:30a.m. - 5:30 p.m. EST</p>
                            
                        </div>
                        <p style="position:absolute;bottom:0;line-height: 24px;"><strong>E-Mail:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  href="mailto:ecommercelatam@avetti.com">ecommercelatam@avetti.com</a><br/>  <strong>Directions:</strong> <a style="color: #039be5; font-size:0.8125em;font-weight:bold;"  target="_blank" href="https://www.google.com/maps/dir/Current+Location/International+Business+Park+Edificio+3825/@8.9275638,-79.5946535,17z/data=!4m8!1m2!2m1!1sInternational+Business+Park+Edificio+3825,+Oficina+101B+Panama+Pacifico,+Panama!3m4!1s0x8faca5ba21c656f7:0x6a5c6200447be59a!8m2!3d8.9273945!4d-79.5923633">Click Here</a></p>
                        
                    </div>
                </div>
                
            </div>

            


        </div>

    </div>`;

export const sellerRegistration = `
    
  

    <div class="row staticContent">

    <h1>Thank you for your interest in the Open For Business Marketplace.</h1>
						
						
						
						<div class="sellerNote">
    
<p><strong>No Cost!</strong></p>
<p>There is no cost for the first 1,000 sellers to each list up to 100 products and product variations by weight, size or colour. All we ask is that you keep your store updated and actively refer us to other merchants.   </p>


<p><strong>Shopper Payment</strong></p>
<p>You can receive payment any way you wish.  Customers can pay you when they pick up products, they can use their Bank to eTransfer or you can use
PayPal, Stripe or Square payment processing solutions. Funds from customers go directly to you we are not in the middle.</p>


<p><strong>Shopper Pickup</strong></p>
<p>If you sell at multiple locations advise us and customers can choose their pickup location.</p>



<p><strong>Your Existing Site</strong></p>
<p>If you have an existing site we can setup a <u>shop.yourdomain.com</u> link that will forward to your store on the <a href="https://openforbusinessmarketplace.ca/" style="vertical-align: baseline; color: #0441cc;">www.openforbusinessmarketplace.ca</a></p>

<p>You can then promote that <u>shop.yourdomain.com</u> link to your customers,</p>



<p><strong>After You Register</strong></p>
<p>Once you register we email you setup a call or a zoom meeting so that we can provide a spreadsheet for you to fill in your products and information on how to send us images.</p>

<p>Thank you for registering.</p>



<p><strong>Contacting Us</strong></p>
<p>If you have any questions you can email us at <a href="mailto:ecommerce@avetti.com" style="vertical-align: baseline; color: #0441cc;">ecommerce@avetti.com</a> and we will respond to your questions via email or call you.</p>
</div>

<br/><br/><br/>
<h2>Seller Sign Up</h2>
<br/><br/>


</div>

 
    
    `;

export const sellerThankYou = `<div class="row staticContent"><h1>THANK YOU FOR SIGNING UP!</h1>
    
    <p>The first step is that we need information about your products.</p>

<p>We accept a simple spreadsheet format and you can find the sample spreadsheet and a video explaining how to add your data at the link below. The video will also explain how to share your images into a dropbox account and how to name them. You can send us 100 products with up to 500 variants. If you only have a few products you can just email the images to us at <a href="mailto:marianae@avetti.ca">marianae@avetti.ca</a>  or <a href="mailto:davids@avetti.ca">davids@avetti.ca</a>  </p>

<p>Here is the link with the video instructions and spreadsheet:</p>

<p><a href="https://www.openforbusinessmarketplace.ca/setup/">https://www.openforbusinessmarketplace.ca/setup/</a>

<p>You can start this right away or you can wait after we have a call. We prefer to use zoom to have a meeting. If you can review the spreadsheet before the call that would be best. If not, we'll explain the process during the zoom call.</p>

<p>Click this link <a href="https://app.hubspot.com/meetings/davids4">https://app.hubspot.com/meetings/davids4</a> to schedule a time to talk to us.</p>

<p>If you can’t do Zoom please advise in the form. If you close this page and forget to schedule a meeting, we’ll email you.</p>

<p>If this is all just too confusing email us at <a href="mailto:ecommerce@avetti.com">ecommerce@avetti.com</a>   or leave a message at <a href="tel:17057972105">705 797 2105</a></p>

<p>Thank you.</p></div>`;

//Start of privacy policy static content
export const privacyPolicy = ` 
<div class="staticContent"><h1>Privacy Policy</h1>

<p>DFS is the reseller division of New England Business Services, a business unit of Deluxe Small Business Sales, Inc. DFS offers private label business products at wholesale prices for resale by qualified dealers. DFS maintains a firm commitment to your privacy. In that effort, we respect and protect the privacy of those who use our Web site, products, and services.</p>

<p>This Privacy Policy, effective June 5, 2019, applies to all personal information we obtain. We have a long history of recognizing and protecting the privacy of our dealers' information. We strive to protect your privacy and utilize technology that gives you a safe and powerful online experience. We want to assure you that when we ask for customer information, our goal is to improve our dealer relationships.</p>

</br><h5>Guiding Principles of Privacy</h5> </br>

<ul style="
line-height: 26px;
">
    <li> <strong>Accuracy:</strong> A commitment to use reasonable procedures to ensure that information collected, used, and exchanged will be accurate and complete.</li>
    <li><strong>Security:</strong> A commitment to use appropriate security measures for protecting information from unauthorized access, use, and disclosure.</li>
    <li><strong>Compliance:</strong> A commitment to collect, use, and exchange data in accordance with applicable laws, and contractual obligations.</li>
    <li><strong>Confedentiality:</strong> A commitment to collect, use, and exchange information in accordance with appropriate confidentiality standards.</li>
    <li><strong>Privacy:</strong> A commitment to self-regulation in recognition of expectations of privacy, our policy, and in accordance with leading industry standards.</li>
    <li><strong>Responsiveness:</strong> A commitment to respond to privacy-related inquiries in a timely and accurate manner.</li>
    <li><strong>Employee Education:</strong> A commitment to educate employees regarding information practices and the proper handling and use of information.</li>
    <li><strong>Self-Auditing:</strong> A commitment to periodic auditing of internal activities to ensure adherence to established information practices, policies, and procedures.</li>
 </ul>

    <p>The following statements will help you understand how we collect, use, and safeguard the information you provide to us. It also describes your individual access rights to your personal information.</p>

    </br><h5>Personal Information You Choose to Provide</h5> 

    <p>At times, we may request that you voluntarily supply personal information for purposes such as acquiring our products or services, requesting information or product samples registering on this website, or making purchases. If you elect to participate, we may require personal information, including your name, mailing address, phone number, bank information, credit card information or email address. When you submit personal information to us, you understand and agree that we may access, store, and use your personal information. Information you provide will be protected according to our strict standards of security and confidentiality.</p>

    </br><h5>Cookie Definition and Use</h5> 

    <p>Cookies are small pieces of information that are stored by your Web browser on your computer's hard drive. A cookie may contain information (such as a unique user session ID) that is used to track the pages of the Web sites you've visited. This Web site uses cookies to improve your shopping experience. The information stored in cookies helps us serve you better by keeping track of your order as you shop at our sites. This information also helps us improve our site design. Most Web browsers automatically accept cookies, but you can set your browser to decline cookies. Even if your Web browser is set to decline cookies, you can use many of the features within this site. However, you will not be able to place an order or retrieve your order status.</p>

    </br><h5>How Collected Information is Used</h5> 

    <p>We are committed to protecting your personal information. We use the information collected about you to provide the services that you’ve requested, process orders, and to provide a more personalized shopping experience. We may also use your information to communicate with you about products, services, and future promotions.</p>

    <p>If you do not want to receive correspondence from us, you may opt out as described below.</p>

    </br><h5>How Collected Information is Shared</h5> 

    <p>We may share your information with business partners and other third parties in order to allow them to provide you with offers, promotions, ads or products, and services that we think you may enjoy. If you do not want us to share your personal information, you may opt out as described below.</p>

    <p>We may also share your information with third parties who provide services on our behalf to help with our business activities. These companies are authorized to use your personal information only as necessary to provide these services to us, pursuant to written instructions. In such cases, these companies must abide by our data privacy and security requirements and are not allowed to use personal information they receive from us for any other purpose. Representative business processes that our service providers/vendors assist us with include: shipping/mailing fulfillment.</p>

    <p>In certain situations, we may be required to disclose personal data in response to lawful requests by public authorities, including to meet national security or law enforcement requirements. We may also disclose your personal information as required by law, such as to comply with a subpoena or other legal process, when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</p>

    </br><h5>Security of Information</h5> 

    <p>Security measures are in place on this Web site to protect against the loss, misuse, and alteration of the information under our control. We offer industry-standard security measures. When you are on this Web site, the information you provide is encrypted and scrambled en route and decoded once it reaches us. Please remember that e-mail, which is different from the encrypted sessions above, is not secure. Therefore, we ask that you do not send confidential information such as social security or account numbers to us via an unsecured e-mail message. Send confidential information to us via U.S. mail or phone.</p>

    <p>We take steps to require that our vendors, consultants, suppliers, and contractors observe our Privacy Policy with respect to security and the collection, use, and exchange of our customers' information. They are expected to abide by the Privacy Policy when conducting work for us.</p>

    </br><h5>Choice to Opt Out</h5> 

    <p>If you wish to opt out of 1) receiving unsolicited marketing mail as a result of your transaction with us or 2) authorizing us to share your personal information with unaffiliated third parties and other organizations. Please see Contacting DFS below for contact information.</p>

    </br><h5>Correct/Update/Access</h5> 

    <p>We are committed to ensuring that the information we obtain and use about our customers is accurate. You can help us maintain accurate records by informing us of changes or modifications to your personal information using one of the methods listed under Contacting DFS. Our service representatives are trained to answer questions about and give you reasonable access to your personal information in our database.</p>

    </br><h5>Target Audience</h5> 

    <p>This web site is intended for Canadian residents only.</p>

    <p>This Web site is directed at prospective and active distributors. We do not knowingly collect or use information from children. Should children access this Web site, we are confident that parents will not judge any of the information provided as objectionable for viewing. If you are under 18, you may use this Web site only with the involvement of a parent or guardian.</p>

    </br> <h5>Other Web Sites Linked to the DFS Web Site</h5>

    <p> We may provide links within our sites and services to the sites or services of third parties. We are not responsible for the collection, use, monitoring, storage or sharing of any personal information by such third parties, and we encourage you to review those third parties' privacy notices and ask them questions about their privacy practices as they relate to you.</p>

    </br><h5>Enforcement</h5> 

    <p>Periodically, our operations and business practices are reviewed for compliance with corporate policies and procedures governing the confidentiality of information. These reviews are conducted by internal staff, internal auditors who report directly to our parent company Board of Directors, external auditing and accounting firms, and government regulators. Included in these self-assessments and examinations are reviews of the controls and safeguards related to consumer privacy described in our Privacy Policy.</p>

    </br><h5>Privacy Policy Changes and Acceptance of These Terms</h5> 

    <p>We may update this Privacy Notice and its effective date to reflect changes to our data governance practices. If we propose to make any material changes, we will notify you by means of a notice on this page prior to the change becoming effective. We encourage you to periodically review this page for the latest information on our privacy practices. By using this Web site, you consent to our posted Privacy Policy. If you do not agree with this Privacy Policy, please do not use this Web site.</p>

    </br><h5>About Registration</h5> 

    <p>DFS provides registration as a service to you, our customer. You choose whether or not to take advantage of it.</p>

    <p>If you register with us, we may store your information in an effort to make your ordering experience faster and easier. After registering, you may log in with your user name and password. Our "auto-login" service allows you to automatically access your purchase history and order status without having to re-key your user name and password.</p>
    

    <p>Here is what we may store for you and why:</p>
     
     <ul style="
     line-height: 20px;
 ">
            <li> Your online purchase history: You don't have to remember what or how much; it allows you to reorder with just one click.</li>
            <li>Your purchasing information (billing, shipping contact, phone, credit card): Frees you from re-keying the information on every order.</li>
            <li>Your line of business and/or accounting software: Gives us the opportunity to offer promotions, products, and advice tailored to meet the needs of your business.</li>
     </ul>

    <p>The information you provide during registration is stored in a secure database. You may modify your purchasing information at any time.</p>

    <p>On the registration form, you may choose to receive various communications from us. If at any time, you would like to discontinue communications (emails, phone calls, faxes, newsletters or advertising from our partners), you may let us know on the registration form.</p>

    </br><h5>About Surveys and Contests</h5> 

    <p>On occasion, DFS may conduct a survey or offer a contest. It will always be your decision whether or not to participate. We use the information gathered through these methods to help us develop new and improved products and market them to you. In addition, the information may help us tailor your experience on our site, showing products and services that would be helpful to your business.</p>

    </br><h5>Contacting DFS</h5> 

    <p>For correction, update, access, and opt-out requests, please contact us at:</p>

  <ul style="
  line-height: 20px;
">
    <li><strong>Email:</strong> <a href="mailto:printing@dfsbusiness.com" style="text-decoration: underline;">printing@dfsbusiness.com</a></li>
    <li><strong>Phone:</strong> 1-800-490-6327 ( 8am to 6pm EST )</li>
    <li><strong>Fax:</strong> 1-800-255-6327</li>
  </ul>

   <p><strong>Mail:</strong> </br>
       Attn: DFS List Services</br>
       330 Cranston Crescent</br>
       Midland, ON L4R 4V9
   </p>
   </br>
   </br>

</div>
`; //End of privacy policy static content. //Also not sure why the </br>s at the end are needed. contactDL has good bottom margin without it.

//TODO: Implement OFB-style contact form?
export const contactDL = `  
<div class=" row staticContent">
<div class="container">
<div class="col-md-12">
<h2 class="statichead">
  Contact Us
</h2>
<div class="col s12 xl6 l6 m6">
  <div class="card">
    <div class="card-content">
      <span class="card-title activator grey-text text-darken-4"  style=" font-size: 1.0625em;">We would like to hear from you! At DFS, we are always looking for ways to improve our service, product and marketing offerings to our valued Dealers.</span>
      <ul style="padding: 30px 20px;">
        <li class="officeadd">
        <i class="material-icons" style="float: left;margin: 7px;">business</i>DFS,330 Cranston Crescent, Midland, Ontario, L4R 4V9.<br>
        </li>
        <li class="officeadd">
        <div class="mail"> 
            <i class="material-icons" style="float: left;margin: 7px;">mail_outline</i>  
            <a href="mailto:printing@dfsbusiness.com">printing@dfsbusiness.com</a>
          </div>
          </li>
        <br>
        <li class="officeadd">
          <strong style="text-transform: uppercase;">You can also directly reach out to us at below numbers:</strong> 
          <br>
          <div class="person"> 
            <i class="material-icons" style="float: left;margin: 7px;">call</i> 800.490.6327 ( 8am to 6pm EST )
          </div>
        </li>
      
      </ul>
    </div>               
  </div>
  
</div>
</div>
</div>
</div>             
`; //end of contactDL static content

//Currently unused. resembles original DL contact page closer.
export const contactDL_v1_old = `
<div class=" staticContent">
    <div class="container">
        
    </div>
</div>             
`; //end of contactDL old static content

//customer service wip
export const customerService = `
<div class=" staticContent">
        <h1>Contact 3</h1>

    <p>We would like to hear from you! At DFS, we are always looking for ways to improve our service, product and marketing offerings to our valued Dealers.</p>
   
    <strong>Email:</strong> <a href="mailto:printing@dfsbusiness.com">printing@dfsbusiness.com</a> <br/><br/>
    <strong>Phone:</strong> 800.490.6327 ( 8am to 6pm EST )<br/><br/>
    <strong>Fax:</strong> 800.255.6327<br/><br/>
    
    <p><strong>Mail:</strong><br/>
    DFS<br/>
    330 Cranston Crescent<br/>
    Midland, Ontario, L4R 4V9</p>
    
    <p>You may also use our <a href="${PREVIEW}/store/20090722001/assets/pdf/Dealer_English_Standard_Order_Form_EDITABLE_VERSION.pdf" style="text-decoration: underline; color: #000000;" >standard order form</a> (PDF must be emailed or faxed).</p>
    </div>             
`; //end of contactDL old static content

export const scripts = {
  sellerRegistration: [
    `hbspt.forms.create({
        portalId: "8090181",
        formId: "bd7d1339-0077-46ff-8711-0d3e2565bceb"
      });`
  ]
};
